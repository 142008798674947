<template>
  <div v-if="!error" :class="size" class="article-contrainer">
    <!-- 文章头 -->
    <article-head
      :loading="loadingBoolean"
      :createTime="articleInfo.createTime"
      :channelName="articleInfo.channelName"
    />
    <!-- 文章简介 -->
    <div class="article-hd">
      <skeleton :loading="loadingBoolean" h="1.1rem" lw="80%">
        <h1 class="title">{{ articleInfo.articleTitle }}</h1>
      </skeleton>
      <div class="details">
        <skeleton :loading="loadingBoolean" :row="1" w="3rem" h=".68rem">
          <div class="sketch">
            <div class="sketch-items">
              <span class="items-label">作者</span>
              <span>{{ articleInfo.authorName }}</span>
            </div>
            <div
              v-if="
                articleInfo.editorName && articleInfo.sourceName === '时代财经'
              "
              class="sketch-items"
            >
              <span class="items-label">编辑</span>
              <span>{{ articleInfo.editorName }}</span>
            </div>
          </div>
        </skeleton>
        <skeleton
          :loading="loadingBoolean"
          :row="1"
          w="6rem"
          h=".68rem"
          class="stat"
        >
          <p class="stat">
            本文 {{ articleInfo.wordNum }}字
            <span v-show="readTime">· 阅读 {{ readTime }}</span>
          </p>
        </skeleton>
      </div>
      <div class="summary">
        <skeleton :loading="loadingBoolean" h=".95rem" lw="90%" spacing=".4rem">
          <p>{{ articleInfo.summary }}</p>
        </skeleton>
      </div>
    </div>
    <!-- 文章正文 -->
    <div class="article-main">
      <div v-if="loadingBoolean" class="article-main__skeleton">
        <skeleton
          :loading="true"
          :row="3"
          h=".95rem"
          style="margin-bottom: 0.88rem"
        />
        <skeleton
          :loading="true"
          :row="6"
          h=".95rem"
          lw="80%"
          style="margin-bottom: 0.88rem"
        />
      </div>
      <div
        v-else
        v-html="articleContent"
        id="uEditorContent"
        class="article-main__content article-content"
      />
    </div>
    <!-- 文章标签 -->
    <div v-if="!loadingBoolean" class="article-tips">
      <div v-if="articleInfo.keywords" class="tips-box">
        <span
          v-for="v in articleInfo.keywords"
          :key="v"
          class="tips-items"
          >{{ v }}</span
        >
      </div>
      <div class="tips-fd">
        <p v-if="articleInfo.sourceName">来源：{{ articleInfo.sourceName }}</p>
        <p class="tips-fd__line">Tips：未经正式授权严禁转载本文，侵权必究</p>
      </div>
    </div>
  </div>
  <err v-else :errStatus="error"></err>
</template>

<script>
import articleHead from "@/components/article-head";
import err from "@/components/err";

function isEmptyObject(obj) {
  for (let key in obj) {
    return false;
  }

  return true;
}

export default {
  name: "articleContrainer",
  components: {
    articleHead,
    err,
  },
  data() {
    return {
      flag: false,
      NOT: "",
      // 字体大小
      size: "M",
      // 错误页面类型
      error: "",
      // 文章详情实体
      articleInfo: {}, // 详情
      articleContent: "", // 内容
      readTime: "", // 阅读时间
    };
  },
  watch: {
    //检测路由变化
    $route(to, from) {
      this.getArticleInfo();
    },
  },
  computed: {
    loadingBoolean() {
      if (this.isCJ) {
        return false;
      }
      return isEmptyObject(this.articleInfo) ? true : false;
    },
  },
  methods: {
    async getArticleInfo() {
      const { token: Authorization, eid } = this.$route.query;

      if (
        (this.$route.query.articleId &&
          this.$route.query.articleId != "undefined") ||
        this.$route.query.scode
      ) {
        let params, res;
        if (this.$route.query.scode) {
          //根据预览链接的scode获取文章详情
          params = {
            scode: this.$route.query.scode,
          };
          res = await this.$api.cj.getResourceDetail(params, { Authorization, eid });
        } else {
          params = {
            articleId: this.$route.query.articleId,
          };
          res = await this.$api.cj.getArticleInfo(params, { Authorization, eid });
        }
        if (res.retCode == 200) {
          res.data.channelName = res.data.channelName || "专题";
          if (res.data.keywords) {
            res.data.keywords = res.data.keywords.split(" ");
          } else {
            res.data.keywords = [];
          }
          //去除空白的关键字
          res.data.keywords = res.data.keywords.filter(v => v)

          this.articleInfo = res.data;
          this.articleContent = this.dealHtml(res.data.articleContent);
        } else if (res.retCode == 400406) {
          //链接已失效
          this.err = "resourceErr01"
        } else if (res.retCode == 400404) {
          this.err = "resourceErr02"
        } else {
          this.err = "serverErr"
        }
      } else if (sessionStorage.getItem("previewData")) {
        let previewData = JSON.parse(sessionStorage.getItem("previewData"))

        //去除空白的关键字
        previewData.keywords = previewData.keywords.filter(v => v)

        this.articleInfo = previewData;
        this.articleContent = this.dealHtml(previewData.content);
      }

      if (this.articleContent) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.showOther = true;
            // 设置阅读时间以及图片绑定点击事件
            let ids = document.getElementById("uEditorContent");
            if (ids) {
              let objs = ids.getElementsByTagName("img");

              let readTime =
                ((this.articleInfo.wordNum / 275) * 60 +
                  (objs.length < 11
                    ? parseInt(objs.length) * 6.23
                    : parseInt(objs.length) * 3)) /
                60;
              this.readTime =
                readTime < 1
                  ? "少于1分钟"
                  : readTime > 1 && readTime < 60
                  ? "约" + readTime.toFixed(0) + "分钟"
                  : "约" +
                    parseInt(readTime.toFixed(0) / 60) +
                    "小时" +
                    (readTime.toFixed(0) -
                      parseInt(readTime.toFixed(0) / 60) * 60) +
                    "分钟";
            }
          }, 50);
        });
      }
    },
    // 正文数据处理
    dealHtml(str) {
      const tLineReg = this.getTagByClassUsingRegex("span", "yellow-line"); // 删除以前的小标题附带子标签
      const tReg = /<h5[^>]*class="articleTile[^\"]*"[^>]*>([^<]*?)<\/h5>/gi; // 修改小标题布局以及样式
      const imgReg = /<p[^>]*>\s*<img([^>|]+)>\s*(<span[^>]*>([^<\/]*)<\/span>)?\s*<\/p>/gi;

      return this.initHtml(str)
      .replace(tLineReg, "")
      .replace(tReg, function (re, $s) {
        return `<p class="time-article-subtitle">${$s}</p>`
      })
      .replace(imgReg, function (re, $s, $z, $c) {
        return `<div id="imgTextBox_0" class="time-article-imgText-box"><img ${$s} data-alt=${$c || ''} />${$z ? '<span class="time-article-imgText-text">'+ $z +'</span>' : ''}</div>`
      })
    },
    // 初始化富文本
    initHtml(str) {
      let s = str;

      s = s.replace(/(\n)/gi, "")
      s = s.replace(/(\t)/gi, "")
      s = s.replace(/(\r)/gi, "")
      s = s.replace(/style=\"[^\"]*\"/gi, "")
      s = s.replace(/<br\/>/gi, "")
      s = s.replace(/<([b-u|w-z][a-z]*?)(?:\s+?[^>]*?)?>\s*?<\/\1>/gi, "")

      return s;
    },
    // 筛选指定claas标签
    getTagByClassUsingRegex(tag, cls) {
      const reg = new RegExp("<" + tag + "[^>]*class[\\s]?=[\\s]?['\"]" + cls + "[^'\"]*['\"][\\s\\S]*?<\/" + tag + ">","g");

      return reg
    },
  },
  created() {
    this.getArticleInfo()
  }
};
</script>

<style lang="scss" scoped>
.article-contrainer {
  padding: 0 0.64rem .64rem;
  box-sizing: border-box;
  &.M {
    .article-hd .summary {
      font-size: 0.68rem !important;
      line-height: 1.16rem !important;
    }
    .article-main {
      font-size: 0.68rem !important;
      line-height: 1.16rem !important;
      margin-top: 0.88rem !important;
      &__content {
        /deep/ {
          >*:not(:last-child) {
            margin-bottom: 0.88rem !important;
          }
        }
      }
      /deep/ .time-article-subtitle {
        font-size: 0.68rem !important;
        line-height: 1.16rem !important;
        &::after {
          width: 0.32rem;
          height: 0.64rem;
          top: 0.195rem;
        }
      }
      /deep/ .time-article-imgText-text {
        font-size: 0.56rem !important;
        line-height: 0.8rem !important;
      }
    }
  }
  // 文章简介
  .article-hd {
    padding-top: 0.56rem;
    word-break: normal;
    .title {
      font-size: 0.96rem;
      font-weight: bold;
      color: #111111;
      line-height: 1.36rem;
    }
    .details {
      margin-top: 1.6rem;
      font-size: 0.48rem;
      font-weight: 400;
      line-height: 0.68rem;
      color: #333333;
      .sketch {
        .sketch-items {
          display: flex;
          align-items: center;
          &:not(:last-child) {
            margin-bottom: 0.16rem;
          }
          .items-label {
            margin-right: 0.2rem;
            border-bottom: 1.5px solid #ffa600;
            font-size: 0.44rem;
            font-weight: bold;
            color: #ffa600;
            line-height: 0.64rem;
          }
        }
      }
      .stat {
        margin-top: 0.4rem;
      }
    }
    .summary {
      margin-top: 1.2rem;
      padding-bottom: 0.16rem;
      border-bottom: 1px solid #ececec;
      font-weight: 400;
      color: #999999;
    }
  }
  // 文章正文
  .article-main {
    color: #111111;
    font-weight: 400;
    // text-align: justify;
    word-break: normal;
    &__content {
      /deep/ img {
        width: 100% !important;
        height: auto !important;
        font-size: 0 !important;
        user-select: none !important;
      }
      /deep/ video {
        width: 100% !important;
        height: auto !important;
      }
    }
    /deep/ .time-article-subtitle {
      position: relative;
      display: inline-block;
      padding-left: 0.8rem;
      font-weight: bold;
      color: #000000;
      &::after {
        content: "";
        position: absolute;
        background: url("~@/assets/images/article_icon_title_sign@2x.png")
          no-repeat;
        background-size: 100% 100%;
        left: 0;
        z-index: 1;
      }
    }
    /deep/ .time-article-imgText-text {
      display: inline-block;
      width: 100%;
      margin-top: 0.44rem;
      font-weight: 400;
      color: #999999;
    }
  }
  // 文章标签
  .article-tips {
    margin-top: 1.28rem;
    .tips-box {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1.2rem;
      .tips-items {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0.84rem;
        background: #131313;
        font-size: 0.48rem;
        font-weight: 500;
        color: #ffa600;
        padding: 0 0.36rem;
        margin-right: 0.32rem;
        margin-bottom: .32rem;
      }
    }
    .tips-fd {
      font-size: 0.48rem;
      font-weight: 400;
      color: #999999;
      line-height: 0.8rem;
      &__line {
        margin-top: 0.08rem;
        padding-top: 0.08rem;
        border-top: 1px solid #c8c8c8;
      }
    }
  }
}
</style>
